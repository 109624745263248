/* You can add global styles to this file, and also import other style files */

h1, h2, h3, h4, h5, h6 {
    font-family: 'Montserrat', sans-serif;
}

ul.dashed {
    list-style-type: none;
    padding-left: 15px;
}

ul.dashed > li {
    text-indent: -15px;
}

ul.dashed > li:before {
    content: "-";
    padding-right: 10px;
}

.invalid {
    width: 100%;
    margin-top: .25rem;
    font-size: 80%;
    color: #dc3545;
}

.modal-dialog {
    max-width: 900px;
}

qrcode img {
    width: 100%;
    max-width: 75vh;
    max-height: 75vh;
    margin: auto;
}

#document-preview qrcode {
    display: block;
    width: 100%;
}

#document-preview qrcode#document-qr img, #document-preview qrcode#document-qr canvas {
    width: 68%;
    float: right;
}

#document-preview qrcode.signature-qr img, #document-preview qrcode.signature-qr canvas {
    width: 60%;
    float: right;
    margin: 10px 0px;
}

.centered-form {
    width: 100%;
    max-width: 330px;
    padding: 15px;
    margin: auto;

}

app-login, app-signup {
    /*display: -ms-flexbox;*/
    /*display: -webkit-box;*/
    /*display: flex;*/
    /*-ms-flex-align: center;*/
    /*-ms-flex-pack: center;*/
    /*-webkit-box-align: center;*/
    /*align-items: center;*/
    /*-webkit-box-pack: center;*/
    /*justify-content: center;*/
    /*padding-top: 40px;*/
    /*padding-bottom: 40px;*/
    /*min-height: 100vh;*/
    height: 100vh;
    width: 100%;
    display: block;
    background-color: #f5f5f5;

}

.centered-form {
    background-color: #f5f5f5;
    border-radius: 5px !important;
    /*border: 1px solid #ced4da;*/
}

#footer-logos {
    width: 100%;
/* max-width: 900px; */
 padding: 15px;
 margin: auto;
}

@media (min-width: 576px) {
 app-login, app-signup {
     background-image: url(assets/img/background.jpg);
     background-position: center;
     background-size: cover;
     background-repeat: no-repeat;
 }
}

#company-logo {
 width: 330px;
}

a {
 color: #e00036;
}

.nav-item a {
  color: #007bff;
}

.btn-theme {
 color: #fff;
 background-color: #e00036;
 border-color: #e00036;
}

.btn-theme:hover {
 color: #fff;
 background-color: #ac052d;
}

.particles-js {
 width: 100%;
 height: 100%;
 min-height: 198px;
 position: absolute;
 z-index: -1;
 background-color: #000;
}

.particles-js-canvas-el {
 top: 0;
 left: 0;
 position: absolute;
}

@media screen and (max-width: 400px) {
 .particles-js {
     min-height: unset;
 }
}

.indicators {
 position: absolute;
 margin-top: 18px;
}

.indicators > .badge {
 font-size: 10px;
}

.indicators > .badge:first-child {
 margin-right: 0px;
}

.logo-big {
 position: relative;
 z-index: 1;
}

footer {
 width: 100%;
 line-height: 60px;
 background-color: #f5f5f5;
 position: relative;
 bottom: 0;
 text-align: center;
}

main {
 min-height: calc(100vh - 162px);
}

.dataTables_scroll {
 margin-bottom: 20px;
}

pre {
 text-align: left;
 overflow-x: auto;
 white-space: pre-wrap;
 white-space: -moz-pre-wrap;
 white-space: -pre-wrap;
 white-space: -o-pre-wrap;
 word-wrap: break-word;
}

#swal2-content .bootstrap-style, #swal2-content .bootstrap-style pre {
 color: #212529;
 font-size: 14px;
 font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

#swal2-content .bootstrap-style pre {
 margin-bottom: 0px;
}

#swal2-content .card-body {
 padding: 10px;
}

#swal2-content .signature-info-box {
 -moz-user-select: none;
 -webkit-user-select: none;
 -ms-user-select: none;
 -o-user-select: none;
 user-select: none;
}

*:disabled i {
 pointer-events: none
}

.btn-danger .fa-times {
 width: 16px;
}

.indicators .badge-success {
 cursor: pointer;
}

.indicators .badge-secondary {
 cursor: default;
}

.scroll {
    height: 200px;
    max-height: 200px;
    overflow-y: auto;
}

.barcode qrcode img {
  width: 0.8in !important;
  height: auto !important;
  margin: 6px 0px -6px 10px;
}
